import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [0,5,4];

export const dictionary = {
		"/(main)": [~46,[5],[6]],
		"/(main)/blog": [~47,[5],[6]],
		"/(main)/blog/[post]/[[title]]": [~48,[5],[6]],
		"/(main)/category/[id]/[[name]]": [~49,[5],[6]],
		"/(dashboard)/dashboard": [7,[2,3]],
		"/(dashboard)/dashboard/admin": [~8,[2,3]],
		"/(dashboard)/dashboard/admin/categories": [9,[2,3]],
		"/(dashboard)/dashboard/admin/clear-accounts": [~10,[2,3]],
		"/(dashboard)/dashboard/admin/emails": [11,[2,3]],
		"/(dashboard)/dashboard/admin/init-custom-flavors": [~12,[2,3]],
		"/(dashboard)/dashboard/admin/inquiries": [13,[2,3]],
		"/(dashboard)/dashboard/admin/playground": [~14,[2,3]],
		"/(dashboard)/dashboard/admin/reflect": [~15,[2,3]],
		"/(dashboard)/dashboard/admin/refresh-config": [~16,[2,3]],
		"/(dashboard)/dashboard/admin/spreadsheet": [~17,[2,3]],
		"/(dashboard)/dashboard/admin/telegram": [18,[2,3]],
		"/(dashboard)/dashboard/analytics/requested-flavors": [~19,[2,3]],
		"/(dashboard)/dashboard/analytics/search": [~20,[2,3]],
		"/(dashboard)/dashboard/blog": [~21,[2,3]],
		"/(dashboard)/dashboard/blog/post/[postid]": [~22,[2,3]],
		"/(dashboard)/dashboard/components": [~23,[2,3]],
		"/(dashboard)/dashboard/inquiries": [~24,[2,3]],
		"/(dashboard)/dashboard/inquiries/[...id]": [~25,[2,3]],
		"/(dashboard)/dashboard/inquiry-stats": [~26,[2,3]],
		"/(dashboard)/dashboard/newsletter": [~27,[2,3]],
		"/(dashboard)/dashboard/not-allowed": [28,[2,3]],
		"/(dashboard)/dashboard/orders": [~29,[2,3]],
		"/(dashboard)/dashboard/orders/[...id]": [~30,[2,3]],
		"/(dashboard)/dashboard/partners": [~31,[2,3]],
		"/(dashboard)/dashboard/prices": [~32,[2,3]],
		"/(dashboard)/dashboard/products": [~33,[2,3]],
		"/(dashboard)/dashboard/products/[...id]": [~34,[2,3]],
		"/(dashboard)/dashboard/resources": [~35,[2,3]],
		"/(dashboard)/dashboard/sale-reps": [~36,[2,3]],
		"/(dashboard)/dashboard/tenders": [~37,[2,3]],
		"/(dashboard)/dashboard/tenders/[...tenderId]": [~38,[2,3]],
		"/(main)/inquiry": [50,[5],[6]],
		"/(main)/inquiry/confirmation/[...inquiryId]": [~51,[5],[6]],
		"/(main)/legal/privacy-policy": [52,[5],[6]],
		"/(links)/links/invoice/[...id]": [~42],
		"/(links)/links/tender/submit-offer/[code]": [43,[4]],
		"/(links)/links/tender/submit-offer/[code]/not-send-reason": [44,[4]],
		"/(links)/links/tender/submit-offer/[code]/withdraw": [45,[4]],
		"/(main)/login2/[...expected]": [~53,[5],[6]],
		"/(main)/order/confirmation/[...orderId]": [~55,[5],[6]],
		"/(main)/order/[...inquiryId]": [~54,[5],[6]],
		"/(main)/product/[productId]/[[name]]": [~56,[5],[6]],
		"/(main)/register/thank-you": [57,[5],[6]],
		"/(main)/search": [58,[5],[6]],
		"/(main)/ui2": [59,[5],[6]],
		"/(dashboard)/ui3": [41,[2]],
		"/(dashboard)/ui": [~39,[2]],
		"/(dashboard)/ui/dp": [40,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';